import axios from 'axios';
import _ from 'lodash';
import { call, put, select } from 'redux-saga/effects';
// import auth from './auth';
import api from './auth';
import { LOGOUT_USER } from 'redux/actions';
// import { LOGIN_FIREBASE } from '../redux/actions'

export const checkResponseData = (response) => {
  if (axios.isCancel(response)) {
    let error = new Error('Request Cancelled');
    error.cancelled = true;
    throw error;
  } else if (response instanceof Error) {
    let ex = response;
    if (ex.response) {
      console.log(ex.response.status)
      console.log(ex.response.data)
      if (ex.response.status === 401) {
        // auth().signOut().then(d => {}).catch(console.error)
      }
      let responseMessage = ex.response.data.description || ex.response.data.msg;
      responseMessage = responseMessage || ex.response.data.message
      if (responseMessage) {
        let error = new Error(responseMessage);
        error.response = ex.response;
        throw error;
      }
    } else {
      console.log(ex);
    }
    throw ex;
  } else if (response.status === 204) {
  } else if (typeof response.data !== 'object') {
    let error = new Error('Invalid Content Type Returned');
    error.response = response;
    throw error;
  } else if (response.data.status === "error") {
    let error = new Error(response.data.description || response.data.msg);
    error.response = response;
    throw error;
  } else if (response.data.errors && response.data.errors.length > 0) {
    let error = new Error(response.data.errors[0]);
    error.response = response;
    throw error;
  }

  return response.data;
}

export const comparePrevCurrent = (nextProps, currentProps, key) => {
  return key ? 
    _.isEqual(_.get(nextProps, key), _.get(currentProps, key)) : 
    _.isEqual(nextProps, currentProps)
}

export const sagaApiRequest = async (axiosPromise) =>
  await axiosPromise.then(checkResponseData).catch(checkResponseData);

export const stardardRequest = ({ action, authProvider, requestOptions, requestOptionsFromPayload, beforeSuccess, afterSuccess, dataPath, dataDefault }) => function* ({ payload }) {
  dataDefault = dataDefault ?? null;
  authProvider = authProvider ?? "firebase";
  requestOptions = requestOptions||{}

  const { successCb, errorCb } = payload;
  delete payload.successCb;
  delete payload.errorCb;
  let data;
  const state = yield select();
  let req;
  try {
    // let bearerToken = null;
    // if (authProvider === 'firebase') {
      // bearerToken = state.auth.token
    // }
    // console.log(state.auth, 'state.auth')
    const bearerToken = state.auth.token
    req = {
      ...requestOptions,
      headers: {
        Authorization: `Bearer ${bearerToken}`,
        'x-app-version': window.__APP_VERSION__||'unset',
        ...(requestOptions.headers||{})
      },
      ...(requestOptionsFromPayload ? requestOptionsFromPayload(payload, state) : {}),
    }
    const response = yield call(sagaApiRequest, api.request(req));
    if (!dataPath) {
      data = response;
    } else {
      data = _.get(response, dataPath, dataDefault);
    }
    if (beforeSuccess) {
      data = yield beforeSuccess(data, state, payload)
    }
    yield put(action.successAction(data))
    if (afterSuccess) {
      yield afterSuccess(data, state, payload)
    }
    if (successCb) {
      successCb(data, state, payload)
    }
  } catch (error) {
    if (error.response) {
      console.log(error.response.request.responseURL)
      console.log(req)

      if (!dataPath) {
        data = error.response.data;
      } else {
        data = _.get(error.response.data, dataPath, dataDefault);
      }

      if (error.response.status === 401) {
        yield put(LOGOUT_USER.requestAction({}))
      }
    }
    if (errorCb) {
      errorCb(error, data, state, payload)
    }
    yield put(action.errorAction(error));
  }
}