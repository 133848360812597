import { all, fork, put, takeEvery } from 'redux-saga/effects';
import { stardardRequest } from '../../utils';
import { API_BASE } from '../../constants/api.constant'

import {
  COMMS_TEMPLATES_CREATE,
  COMMS_TEMPLATES_DELETE,
  COMMS_TEMPLATES_EDIT,
  COMMS_TEMPLATES_FETCH,
  COMMS_TEMPLATES_FETCH_BY_ID,
  COMMS_TEMPLATES_SET_FILTERS,
} from '../actions';

export function* watchCommsTemplatesFetchRequest() {
  const action = COMMS_TEMPLATES_FETCH
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload, state) => {
      const filters = state.comms.grid_options.templates.filters||{}
      return {
        method: 'post',
        url: `${API_BASE}/v1/comms/template/fetch`,
        data: {...filters}
      }
    },
    dataPath: 'result',
    dataDefault: [],
  }));
}

export function* watchCommsTemplatesFiltersRequest() {
  yield takeEvery(COMMS_TEMPLATES_SET_FILTERS.REQUEST, function* ({ payload }) {
    yield put(COMMS_TEMPLATES_FETCH.requestAction({}))
  })
}

export function* watchCommsTemplatesFetchIdRequest() {
  const action = COMMS_TEMPLATES_FETCH_BY_ID
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'get',
        url: `${API_BASE}/v1/comms/template/fetch/${payload.id}`
      }
    },
    dataPath: 'result',
    dataDefault: null,
  }));
}

export function* watchCommsTemplatesCreateRequest() {
  const action = COMMS_TEMPLATES_CREATE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'post',
        url: `${API_BASE}/v1/comms/template`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null,
  }));
}

export function* watchCommsTemplatesEditRequest() {
  const action = COMMS_TEMPLATES_EDIT
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'patch',
        url: `${API_BASE}/v1/comms/template`,
        data: payload
      }
    },
    dataPath: 'result',
    dataDefault: null,
  }));
}

export function* watchCommsTemplatesDeleteRequest() {
  const action = COMMS_TEMPLATES_DELETE
  yield takeEvery(action.REQUEST, stardardRequest({
    action: action,
    authProvider: 'firebase',
    requestOptionsFromPayload: (payload) => {
      return {
        method: 'delete',
        url: `${API_BASE}/v1/comms/template/${payload.id}`
      }
    },
    dataPath: 'result',
    dataDefault: null,
  }));
}

export default function* rootSaga() {
  yield all([
    fork(watchCommsTemplatesFetchRequest),
    fork(watchCommsTemplatesFetchIdRequest),
    fork(watchCommsTemplatesFiltersRequest),
    fork(watchCommsTemplatesCreateRequest),
    fork(watchCommsTemplatesEditRequest),
    fork(watchCommsTemplatesDeleteRequest),
  ]);
}