import { REHYDRATE } from 'redux-persist';
import {
  REPORT_PRE_QUAL
} from '../actions';
import _ from 'lodash'

const INIT_STATE = {
  pre_qual: null
};

const handler = (state = INIT_STATE, action) => {
  switch (action.type) {
    case REPORT_PRE_QUAL.SUCCESS: {
      const { report, url } = action.payload
      return { ...state, [report]: url }
    }
    case REHYDRATE: {
      return INIT_STATE
    }
    default: return { ...state };
  }
}

export default handler
