import { eventChannel, END } from 'redux-saga';
import ReconnectingWebSocket from "reconnecting-websocket";
import { all, call, take, put, fork, cancel, cancelled, takeEvery, select } from 'redux-saga/effects';
import { EVENTS_CONNECT, EVENTS_CONNECTED, EVENTS_DISCONNECT, EVENTS_SEND_EVENT, LEADS_FETCH_QA_BY_ID, LEADS_LOCKED } from 'redux/actions';

const wssHassUrl = 'wss://cw-prequal.directaffinitycx.net/ws';
const dispatch = put;
let connection;
let settingUp = false

const createWebSocketConnection = (api_key) => {
  return new Promise(async (resolve, reject) => {
    try {
      let ws = new ReconnectingWebSocket(`${wssHassUrl}?access_token=${api_key}`, null, {reconnectInterval: 5000});
      resolve(ws);
    } catch (ex) {
      console.log('createWebSocketConnection', ex)
      reject(ex)
    }
  });
}

const createSocketChannel = (connection) => {
  return eventChannel(emit => {
    connection.onconnecting = () => {
      console.log('connecting')
      emit({ type: 'connecting' });
    };
    connection.onopen = () => {
      console.log('onopen')
      emit({ type: 'connected' });
    };
    connection.onmessage = (message) => {
      var event = JSON.parse(message.data);
      emit(event);
    };
    connection.onerror = (error) => {
      console.log(error)
      console.log('connection.onerror', error.message)
      emit({ type: 'error', error });
    };
    connection.onclose = function () {
      console.log('connection.onclose')
      console.log('emitting connection_closed')
      emit({ type: 'connection_closed', id: null });
      // emit(END);
    };
    const unsubscribe = () => { };
    return unsubscribe;
  });
}

function* listenForSocketMessages(api_key) {
  let socketChannel;
  try {
    console.log('Setting up Events');
    settingUp = true
    connection = yield call(createWebSocketConnection, api_key);
    socketChannel = yield call(createSocketChannel, connection);

    // tell the application that we have a connection
    yield dispatch(EVENTS_CONNECT.successAction());

    while (true) {
      // wait for a message from the channel
      try {
        const payload = yield take(socketChannel);
        if (payload && payload.type) {
          const { type } = payload;
          if (type === 'connecting') {
            console.log('Connecting')
          } else if (type === 'connected') {
            console.log('Connected')
            settingUp = false
            yield dispatch(EVENTS_CONNECTED.requestAction({}));
          } else if (type === 'listings_locked') {
            const { listings } = payload
            yield dispatch(LEADS_LOCKED.requestAction({ listings }));
          } else if (type === 'call_qa_complete') {
            console.log(payload,'payload')
            const { data: { listing_id } } = payload
            yield dispatch(LEADS_FETCH_QA_BY_ID.requestAction({ id: listing_id }));
          }
        } else if (payload === END) {
          console.log('End Emitted')
          // connection = yield call(createWebSocketConnection);
          // socketChannel = yield call(createSocketChannel, connection);
        }
      } catch (ex) {
        console.log('message error')
        console.log(ex)
        console.log(ex.message)
        console.log(ex.stack)
      }
    }
  } catch (ex) {
    console.log('connect error')
    console.log(ex)
    console.log(ex.stack)
    yield dispatch(EVENTS_DISCONNECT.requestAction(ex));
  } finally {
    if (yield cancelled()) {
      console.log('HASS cancelled')
      // close the channel
      if (socketChannel) {
        console.log('Calling socketChannel.close')
        socketChannel.close();
      }
      // close the WebSocket connection
      if (connection) {
        console.log('Calling connection.close')
        connection.close();
      }
      connection = null;
    } else {
      console.log('HASS reconnect error')
      const ex = new Error('Reconnect Error, Auth Failed');
      ex.line = 189;
      yield dispatch(EVENTS_DISCONNECT.requestAction(ex));
    }
  }
}

function* connect() {
  const state = yield select();
  const { auth: { token } } = state;
  // console.log(api_key, 'connect: api_key')
  if (token && token.length > 0 && (!connection || connection.readyState === WebSocket.CLOSED) && !settingUp) {
    // starts the task in the background
    const socketTask = yield fork(listenForSocketMessages, token);

    // when DISCONNECT action is dispatched, we cancel the socket task
    yield take(EVENTS_DISCONNECT.REQUEST);
    yield cancel(socketTask);
  }
  yield dispatch(EVENTS_DISCONNECT.successAction());
}

function* sendEvent(action) {
  if (connection && connection.readyState !== WebSocket.CLOSED) {
    connection.send(JSON.stringify(action.payload))
  }
  yield dispatch(EVENTS_DISCONNECT.successAction());
}

export function* watchConnect() {
  yield takeEvery(EVENTS_CONNECT.REQUEST, connect);
}

export function* watchSendEvent() {
  yield takeEvery(EVENTS_SEND_EVENT.REQUEST, sendEvent);
}

export default function* rootSaga() {
  yield all([
    fork(watchConnect),
    fork(watchSendEvent),
  ]);
}