import { REHYDRATE } from 'redux-persist';
import {
  EVENTS_CONNECT,
  EVENTS_DISCONNECT,
  EVENTS_CONNECTED,
} from '../actions';

const INIT_STATE = {
  connected: false
};

const handler = (state = INIT_STATE, action) => {
  switch (action.type) {
    case EVENTS_CONNECT.REQUEST: {
      return { ...state, connected: false }
    }
    case EVENTS_CONNECTED.REQUEST: {
      return { ...state, connected: true }
    }
    case EVENTS_DISCONNECT.REQUEST: {
      return { ...state, connected: false }
    }
    case REHYDRATE: {
      return INIT_STATE
    }
    default: return { ...state };
  }
}

export default handler
