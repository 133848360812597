const appConfig = {
    apiPrefix: '/api',
    authenticatedEntryPath: '/app/listing/list',
    authenticatedEntryPathAdminExpert: '/app/report/pre-qual',
    authenticatedEntryPathStandard: '/app/listing/list',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/app/account/kyc-form',
    enableMock: true
}

export default appConfig