import React from 'react';
import { useUpdateCheck } from 'react-update-notification';

const NotificationContainer = () => {
  const { status, reloadPage } = useUpdateCheck({
    type: 'interval',
    // interval: 60000,
    interval: 10000,
    ignoreServerCache: true
  });

  console.log(status, 'status')
  if (status === 'checking' || status === 'current') {
    return null;
  }

  return (
    <div style={{
      backgroundColor: '#212121',
      color: '#FFC400',
      position: 'absolute',
      right: 0,
      bottom: 0,
      padding: 16,
      borderRadius: 0
    }}>
      <button type="button" onClick={reloadPage}>
        Refresh to update the app
      </button>
    </div>
  );
};

export default NotificationContainer