import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  REGISTER_USER,
  REGISTER_USER_SUCCESS,
  LOGOUT_USER,
  INITIAL_ROUTE,
  FB_TOKEN_CHANGED,
  PUSH_REGISTER_STORE,
  LOGIN_FIREBASE,
  AUTH_RESET,
  ROLE_REQUEST
} from '../actions';

import { REHYDRATE } from 'redux-persist';

const INIT_STATE = {
  user: null,
  username: null,
  name: null,
  profile_picture: null,
  roles: [],
  jwt_token: null,
  fb_token: null,
  push_token: null,
  loading: false,
  initialRoute: null,
  token: null,
};

const handler = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER:
      return { ...state, loading: true };
    case LOGIN_FIREBASE:
      return { ...state, token: action.payload.token }
    case LOGIN_USER_SUCCESS:
      const { _id, username, name, first_name, last_name , initials, profile_picture, roles, token } = action.payload.user;
      return {
        ...state,
        loading: false,
        user: _id,
        username,
        first_name, 
        last_name, 
        initials,
        name,
        profile_picture,
        roles: roles.map(r => r.toLowerCase()),
        token
      };
    case REGISTER_USER:
      return { ...state, loading: true };
    case REGISTER_USER_SUCCESS:
      return { ...state, loading: false, user: action.payload.uid };
    case LOGOUT_USER.REQUEST:
      return { ...state, ...INIT_STATE };
    case INITIAL_ROUTE:
      return { ...state, initialRoute: action.payload.initialRoute };
    case FB_TOKEN_CHANGED.REQUEST:
      return { ...state, fb_token: action.payload.token }
    case PUSH_REGISTER_STORE:
      return { ...state, push_token: action.payload.token };
    case AUTH_RESET.SUCCESS:
      return { ...state, roles: [] };
    case ROLE_REQUEST.SUCCESS:
      return { ...state, roles: action.payload.roles };
    case REHYDRATE: {
      // console.log(action.payload.auth ? action.payload.auth : null)
      return { ...INIT_STATE, ...state, ...(action.payload && action.payload.auth ? action.payload.auth : INIT_STATE) }
    }
    default: return { ...state };
  }
}

export default handler