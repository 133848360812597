
import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
// import { auth } from '../../helpers/Firebase';
import { SERVICE_PATH } from '../../constants/default.constant';
import axios from 'axios';
import { sagaApiRequest, stardardRequest } from '../../utils';

import log from '../../utils/log';
// logger.setLevel('info')

import {
  // LOGIN_USER,
  LOGIN_FACEBOOK,
  LOGOUT_USER,
  PUSH_REGISTER,
  VERSION_CHECK,
  ROLE_REQUEST
} from '../actions';

import {
  loginUserSuccess,
  // registerUserSuccess,
  // pushRegister,
  pushRegisterStore,
  pushRegisterSuccess,
  pushRegisterError,
} from './actions';
import { API_BASE } from 'constants/api.constant';

const logger = log.getLogger("AUTH");

const loginWithFacebookAsync = async (accessToken) =>
  await axios.post(`${SERVICE_PATH}/auth/facebook`, { access_token: accessToken })
    .then(response => response.data)
    .catch(error => error);

function* loginWithFacebook({ payload }) {
  const { _token } = payload.user;
  const { accessToken } = _token;
  logger.debug('loginWithFacebook: starting')
  try {
    const auth = yield call(loginWithFacebookAsync, accessToken);
    if (auth.user) {
      logger.debug('loginWithFacebook: success')
      yield put(loginUserSuccess(auth));
    } else {
      logger.debug('loginWithFacebook failed:', auth);
    }
  } catch (error) {
    logger.debug('loginWithFacebook error: ', error)
  }
}

const pushRegisterRequestAsync = async (jwt_token, postData) =>
  await axios.post(`${SERVICE_PATH}/api/v1/notification/register/token`, postData, { headers: { Authorization: `Bearer ${jwt_token}` } })
    .then(response => response.status)
    .catch(error => error);

function* pushRegisterRequest({ payload }) {
  let { token } = payload;
  try {
    const state = yield select();
    if (token) {
      logger.debug('Storing New Push Token')
      yield put(pushRegisterStore({ token }))
    } else if (state.auth.push_token) {
      logger.debug('Using Stored Token')
      token = state.auth.push_token;
    }
    if (token && state.auth.jwt_token) {
      const status = yield call(pushRegisterRequestAsync, state.auth.jwt_token, token);
      if (status === 200) {
        logger.debug('Push register success')
        yield put(pushRegisterSuccess());
      } else {
        throw Error(`Push Registration failed with status: ${status}`)
      }
    }
  } catch (ex) {
    logger.error(ex)
    yield put(pushRegisterError(ex));
  }
}


/*const logoutAsync = async (navigation) => {
    // await auth.signOut().then(auth => auth).catch(error => error);
    navigation.navigate('Login')
}


function* logout({payload}) {
    const { navigation } = payload
    try {
        yield call(logoutAsync,navigation);
        // localStorage.removeItem('user_id');
    } catch (error) {
    }
}*/


/*
export function* watchRegisterUser() {
    yield takeEvery(REGISTER_USER, registerWithEmailPassword);
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}
*/

export function* watchLoginFacebook() {
  yield takeEvery(LOGIN_FACEBOOK, loginWithFacebook);
}

export function* watchPushRegister() {
  yield takeEvery(PUSH_REGISTER, pushRegisterRequest);
}

function* versionCheck({ payload }) {
  const action = VERSION_CHECK;
  const { success } = payload;
  try {
    const state = yield select();
    const response = yield call(sagaApiRequest, axios.post(
      `${SERVICE_PATH}/api/v1/app/dist/update-check`,
      payload,
      { 
        headers: { Authorization: `Bearer ${state.auth.jwt_token}` }
      }
    ));
    if (success) {
      logger.debug('Version Check Resp', response)
      const { result: { update_required, latest_version, update_url } } = response;
      success(update_required, latest_version, update_url);
    }
    yield put(action.successAction())
  } catch (error) {
    logger.debug('Version Check Error', error)
    yield put(action.errorAction(error));
  }
}

export function* watchVersionCheck() {
  yield takeEvery(VERSION_CHECK.REQUEST, versionCheck);
}

export function* watchRoleRequest() {
  yield takeEvery(ROLE_REQUEST.REQUEST, stardardRequest({
    action: ROLE_REQUEST,
    requestOptions: {
      url: `${SERVICE_PATH}/api/v1/auth/roles`, 
      method: 'get'
    },
    dataPath: null, 
    dataDefault: {}
  }));
}

export function* watchLogoutRequest() {
  yield takeEvery(LOGOUT_USER.REQUEST, stardardRequest({
    action: LOGOUT_USER,
    requestOptions: {
      url: `${API_BASE}/v1/auth/signout`, 
      method: 'get'
    },
    dataPath: null, 
    dataDefault: {}
  }));
}

export default function* rootSaga() {
  yield all([
    // fork(watchLoginUser),
    fork(watchLoginFacebook),
    fork(watchPushRegister),
    fork(watchVersionCheck),
    fork(watchRoleRequest),
    fork(watchLogoutRequest),
    // fork(watchLogoutUser),
    // fork(watchRegisterUser)
  ]);
}