import { combineReducers } from 'redux';
import auth from './auth/reducer';
import api from './api/reducer'
import metrics from './metrics/reducer'
import towers from './towers/reducer'
import theme from './theme/reducer'
import base from './base/reducer'
import leads from './leads/reducer'
import comms from './comms/reducer'
import events from './events/reducer'
import reports from './reports/reducer'

const reducers = combineReducers({
  api,
  auth,
  metrics,
  towers,
  theme,
  base,
  leads,
  comms,
  events,
  reports
});

export default reducers;