import { REHYDRATE } from 'redux-persist';
import {
  COMMS_TEMPLATES_SELECTED_CLEAR,
  COMMS_TEMPLATES_FETCH,
  COMMS_TEMPLATES_FETCH_BY_ID,
  COMMS_TEMPLATES_SET_FILTERS,
} from '../actions';
import _ from 'lodash'

const INIT_STATE = {
  list: [],
  list_paging: { total_rows: 0, page_size: 25, page_indx: 0 },
  template: null,
  grid_options: {
    templates: {
      crud_modal: null,
      crud_data: null,
      view: 'list',
      sort: 'asc',
      search: null,
      filters: {
        stage: 1
      }
    }
  }
};

const getKey = (action) => {
  let key = null
  const allKeys = ['templates']
  if (action.type.indexOf('COMMS_TEMPLATES_') === 0) {
    key = 'templates'
  }
  return { key, remaining: allKeys.filter(a => a !== key) }
}

const handler = (state = INIT_STATE, action) => {
  switch (action.type) {
    case COMMS_TEMPLATES_FETCH.REQUEST: {
      const approval_method = _.get(action, 'payload.approval_method')
      const stage = _.get(action, 'payload.stage')
      const outcome = _.get(action, 'payload.outcome')
      const page = _.get(action, 'payload.page')
      const page_size = _.get(action, 'payload.page_size')
      const { key } = getKey(action)
      let updates = {}
      if (approval_method !== undefined) {
        updates.approval_method = approval_method
      }
      if (stage !== undefined) {
        updates.stage = stage
      }
      if (page !== undefined) {
        updates.page = page
      }
      if (page_size !== undefined) {
        updates.page_size = page_size
      }
      updates.outcome = outcome ? outcome : undefined
      return { ...state, list: [], grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          filters: {
            ...state.grid_options[key].filters,
            ...updates
            // approval_method: action.payload.approval_method
          }
        },
      } }
    }
    case COMMS_TEMPLATES_FETCH.SUCCESS: {
      const { rows, total_rows, page_size, page_indx } = action.payload
      return { ...state, list: rows, list_paging: { total_rows, page_size, page_indx } }
    }
    case COMMS_TEMPLATES_SELECTED_CLEAR.REQUEST: 
    case COMMS_TEMPLATES_FETCH_BY_ID.REQUEST: {
      return { ...state, template: null }
    }
    case COMMS_TEMPLATES_FETCH_BY_ID.SUCCESS: {
      return { ...state, template: action.payload }
    }
    case COMMS_TEMPLATES_SET_FILTERS.REQUEST: {
      const { key } = getKey(action)
      return { ...state, grid_options: {
        ...state.grid_options,
        [key]: {
          ...state.grid_options[key],
          filters: {
            ...state.grid_options[key].filters,
            ...action.payload,
          }
        },
      } }
    }
    case REHYDRATE: {
      return INIT_STATE
    }
    default: return { ...state };
  }
}

export default handler
